import React from 'react';
import { StructuredText as ReactStructuredText } from 'react-datocms';
import { isLink, renderRule } from 'datocms-structured-text-utils';
import AppLink from 'src/components/base/appLink';

function renderTextWithTokens(text, tokenValues) {
  let ret = text;
  if (tokenValues) {
    Object.entries(tokenValues).forEach(([token, value]) => {
      ret = ret.replace(new RegExp('\\$' + token, 'g'), value);
    });
  }
  return ret;
}

const StructuredText = ({ data, tokenValues }) => (
  <ReactStructuredText
    data={data}
    // Replace tokens like the callback time with values
    renderText={(text) => renderTextWithTokens(text, tokenValues)}
    // Use `AppLink` instead of `a` for rendering links to allow internal links without reload
    customNodeRules={[
      // see https://github.com/datocms/structured-text/blob/main/packages/generic-html-renderer/src/index.ts#L233
      renderRule(isLink, ({ key, children, node }) => {
        return (
          <AppLink key={key} to={node.url}>
            {children}
          </AppLink>
        );
      }),
    ]}
  />
);

export default StructuredText;
